@tailwind base;
@tailwind components;

@import "_menu";
@import "styles";

@tailwind utilities;

body{
    font-family: 'Work Sans', sans-serif;
}

.container{
    margin:0 auto;
    max-width:450px;
    @media(min-width:992px){
        max-width:1256px;
    }
}

#footer{
    padding:39px 20px 38px;
    div{
        p{}
        ul{
            @media(max-width:991px){
                margin: 1em 0 1.5em 0;
            }
            li{
                margin: 0 0 10px 0;
                @media(min-width:992px){margin: 0 0 0 31px;}
            }
        }
    }
}

.present{
    min-height: calc(100vh - 101px);
    padding:50px 0px;
    background-image: url('../media/images/bg_desk.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .container{
        grid-gap:40px;
        @media(min-width:992px){
            max-width:1032px;
        }
    }
    .text-container{   
        padding: 0px 27px;
        @media(min-width:992px){
            padding:17px 20px;
        }
        p{
            font-family: 'Work Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            margin:48px 0 58px;
        }
        
    }
    .btn-group{
        @media(min-width:992px){
            a{
                opacity:1;
                transition: opacity .3s;
                &:hover{
                    opacity:.65;
                    transition: opacity .5s;
                }
            }
        }           
    }
    .canvas{
        text-align: center;
        padding:0 20px;
        @media(min-width:992px){
             text-align: right;
        }
        img{
            display: inline-block;
        }
        .media-container{
            display: inline-block;
            video{
                position: absolute;
                top: 3%;
                left: 3%;
                height: 93%;
                width: 96%;
                object-fit: contain;
                object-position: center;
            }
            img{
                position: relative;
                display: block;
                pointer-events: none;
            }
        }
    }
    .submit-form{
        margin-top:50px;
        display: block;
        .msg{
            background:#888;
            color:#fff;
            border-radius:10px;
            max-height:0px;
            padding:0;
            transition: max-height .1s, padding .1s;
            -webkit-transition: max-height .1s, padding .1s;

            &.error{
                background:#f57272;
            }
            &.success{
                background:#5ad07f;
            }
            &.active{
                padding:1px;
                max-height:500px;
                transition: max-height 1s;
                -webkit-transition: max-height 1s, padding .1s;
            }
            p{
                margin: 16px 25px;
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    form{
        font-family: 'Work Sans', sans-serif;
        
        display: grid;
        grid-template-columns:1fr;
        background: #FFFFFF;

        @media(min-width:375px){
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 10px;
            grid-template-columns:1fr 126px;
            overflow:hidden;
        }
       input{
           &[type="email"],&[type="text"]{
               font-family: 'Work Sans', sans-serif;
                outline: 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;

                color: #000000;
                padding: 25px 35px 25px 35px;
                @media(max-width:374px){
                    border: 1px solid #000000;
                    box-sizing: border-box;
                    border-radius: 10px;
                }
                @media(min-width:374px)and(max-width:992px){
                    padding: 25px 1em 25px;
                }

           }
           &[type="submit"],&[type="button"]{
               font-family: 'Work Sans', sans-serif;
                background: #000000;
                outline: 0;
                -webkit-border-top-left-radius: 10px;
                -webkit-border-bottom-left-radius: 10px;
                -moz-border-radius-topleft: 10px;
                -moz-border-radius-bottomleft: 10px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                @media(max-width:374px){
                    border-radius: 10px;
                }

                cursor:pointer;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;

                color: #FFFFFF;
                padding: 25px 35px 25px 35px;
                @media(min-width:374px)and(max-width:992px){
                    padding: 25px 1em 25px;
                }
                @media(min-width:992px){

                    opacity:1;
                    transition: opacity .3s;
                    &:hover{
                        opacity:.65;
                        transition: opacity .5s;
                    }
                }
           }
       }
    }
}

#terms-template{
    min-height: calc(100vh - 101px);
    padding:50px 0px;
    .container,.header{
        margin:0 auto;
        max-width:450px;
        @media(min-width:992px){
            max-width:1046px;
        }
        img{
            &.logo{
                max-width:246px;
                padding:0 0 40px;
            }
        }
        &.grid{
            grid-gap: 30px;
            grid-template-columns: 1fr;
            @media(min-width:992px){
                grid-gap: 7%;
                grid-template-columns: 1fr 67%;
            }
            
        }   
    }
    .terms-navigation{
        ul{
            li{
                a{
                    display: block;
                    width:100%;
                    font-family: Work Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 21px;
                    border-radius: 10px;
                    padding:12px 25px 12px 33px;
                    text-align: center;

                    color: #000000;
                    transition:background .5s, color .3s, font-weight .1s;
                    &.active{
                        background: #000000;
                        font-weight: bold;
                        color:white;
                        transition:background .8s, color .5s, font-weight .3s;
                    }
                    @media(min-width:992px){
                        &:hover{
                            //background: #000000;
                            font-weight: bold;
                            //color:white;

                            transition:background .8s, color .5s, font-weight .3s;
                        }
                    }
                }
            }
        }
    }
    .text-content{
        h1{
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            margin:10px 0 24px;
        }
        h2{
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 28px;
            margin:10px 0 24px;
        }
        p{
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            margin:10px 0 15px;
        }
        ul{
            list-style-type: disc;
            margin-left: 24px;
        }
        table{
            text-align: left;
            border-collapse: collapse;
            td,th{
                padding: 10px;
                vertical-align: top;
            }
        }
    }
}