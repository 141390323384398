#menu {
	.container{
		max-width:100%;
		@media(min-width:992px){
			max-width:1338px;
		}
	}
	.links {
		a {
			&:hover {}
			@screen md {}
			@screen lg {}
		}
	}
	&.scrolling {
		@screen md {
			transition: all 0.3s linear;
		}
	}
}
